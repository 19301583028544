<template>
  <div class="body">
    <section class="banner">
      <h2>橙仕<strong>工业系统</strong></h2>
      <p>橙仕有<strong>最完整的工业 4.0解决方案</strong><br />通过数字化技术大幅提高企业<br class="mShow" />应对内部、外部变化的响应能力<br />将<strong>智能传感器</strong>、<strong>分析技术</strong><br class="mShow" />和<strong>人工智能</strong>结合起来<span class="mHide">，</span><br class="mShow" />实时监控运营和潜在的中断<br />使生产保持在最优级别运行</p>

      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/index/m/capacity.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/banner.jpg"
          width="1490" height="1100"
          alt="橙仕工业系统" 
        />
      </picture>
    </section>
    
    <section class="box structure">
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/m/structure.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/structure.png"
          width="1100" height="347"
        />
      </picture>
      <h3><strong>组织架构数字化</strong></h3>
      <p>通过对职能组织结构重构，层级上扁平化，业务上数据化<br />最终实现生产、采购、设计、研发、市场同频共振快速响应</p>
    </section>

    <section class="box">
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/m/digitalfactory.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/digitalfactory.png"
          width="1100" height="300"
        />
      </picture>
      <h3><strong>数字化工厂</strong></h3>
      <p>数字化工厂，项目全流程数字化拆解，<br class="mShow" />传统多工种实现在线协同工作<br />致力实现工程全生命周期数字化管理</p>
    </section>

    <section class="box aifactory">
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/m/aifactory.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/aifactory.png"
          width="1100" height="236"
        />
      </picture>
      <p>四大工艺车间，高度自动化生产</p>
      <h3>打造<strong>顶尖的智造工厂</strong></h3>
    </section>

    <section class="box ai">
      <ul class="cases">
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/digitizing.png" alt="数字化" width="246" height="206" />
          <p><strong>数字</strong>化</p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/scale.png" alt="规模化" width="246" height="206" />
          <p><strong>规模</strong>化</p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/standardized.png" alt="标准化" width="246" height="206" />
          <p><strong>标准</strong>化</p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/automation.png" alt="自动化" width="246" height="206" />
          <p><strong>自动</strong>化</p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/industrial/flexible.png" alt="柔性化" width="246" height="206" />
          <p><strong>柔性</strong>化</p>
        </li>
      </ul>
      <h3>全域统筹智能决策</h3>
      <p>通过大数据和AI算法，重塑工程数字模块化<span class="mHide">，</span><br class="mShow" />实现工厂全域统筹智能决策。</p>
    </section>
  </div>
</template>
<script>
export default {
  name: "Industrial",
};
</script>
<style lang="scss" scoped>
.banner {
  margin-bottom: 128px;
  position: relative;
  padding-top: 280px;
  height: 1100px;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  background-color: #e83e0b;

  h2 {
    position: relative;
    margin-bottom: 440px;
    font-size: 100px;
    line-height: 120px;
    color: #fff;
    z-index: 5;
  }

  p {
    position: relative;
    z-index: 5;
  }

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.box {
  margin-bottom: 120px;
  padding: 0 36px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #222226;

  h3 {
    margin-bottom: 8px;
    font-size: 60px;
    color: #222226;
    line-height: 80px;
  }

  img {
    display: block;
    margin: 0 auto 22px;
    max-width: 100%;
    height: auto;
  }

  &.structure {
    img {
      margin-bottom: 12px;
    }
  }

  &.aifactory {
    font-size: 40px;
    line-height: 60px;

    img {
      margin-bottom: 46px;
    }

    h3 {
      margin: 0;
    }
  }

  &.ai {
    .cases {
      margin: 0 auto 128px;
      display: flex;
      max-width: 1474px;
      justify-content: space-between;

      li {
        flex: 1;
        margin: 0 12px;
        max-width: 246px;

        p {
          margin-top: 32px;
          font-size: 40px;
          line-height: 60px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 74px;
    padding-top: 180px;
    height: 640px;
    font-size: 12px;
    line-height: 16px;

    h2 {
      margin-bottom: 40px;
      font-size: 50px;
      line-height: 60px;
    }

    p {
      margin: 0 auto;
      padding: 0 20px;
      max-width: 240px;
    }

    img {
      top: 296px;
      width: 100%;
      height: auto;
    }
  }

  .box {
    margin-bottom: 76px;
    padding: 0;
    font-size: 12px;
    line-height: 16px;

    h3 {
      margin-bottom: 0;
      font-size: 30px;
      line-height: 50px;
    }

    img {
      margin-bottom: 12px;
    }

    &.structure {
      img {
        margin-bottom: 2px;
      }
    }

    &.aifactory {
      font-size: 20px;
      line-height: 30px;

      img {
        margin-bottom: 24px;
      }

      h3 {
        margin: 0;
        line-height: 42px;
      }
    }

    &.ai {
      .cases {
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          flex: auto;
          margin: 0 12px 55px;
          max-width: 98px;
          width: 25%;

          p {
            margin-top: 15px;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

</style>